$DATAGRID_THEME_NAME: 'dorim-theme';
$INOVUA_DATAGRID_ACCENT_COLOR: #84a9ff;
$INOVUA_DATAGRID_BORDER_COLOR: #e5e8eb;
$INOVUA_DATAGRID_FONT_COLOR: #212b36;
$INOVUA_DATAGRID_ROW_ACTIVE_BORDER_COLOR: $INOVUA_DATAGRID_ACCENT_COLOR;
$INOVUA_DATAGRID_ROW_EVEN_HOVER_BG_COLOR: #ffffff;

// ----------------------------------------------------------------------

@import '~@inovua/reactdatagrid-community/style/base';
@import '~@inovua/reactdatagrid-community/style/theme/default-light/index';

// ----------------------------------------------------------------------

.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  border: none;

  .InovuaReactDataGrid__header-wrapper {
    .InovuaReactDataGrid__header-wrapper__fill {
      display: none;
    }
  }

  .InovuaReactDataGrid__header {
    border-bottom: none;

    .InovuaReactDataGrid__column-header__resize-wrapper {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        bottom: 0;
        background-color: $INOVUA_DATAGRID_BORDER_COLOR;
      }
    }
  }

  .InovuaReactDataGrid__row-index-column {
    background-color: transparent;
  }

  .InovuaReactDataGrid__column-header__content {
    color: $INOVUA_DATAGRID_FONT_COLOR;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 12px;
  }

  .InovuaReactDataGrid__nested-group-cell,
  .InovuaReactDataGrid__nested-cell,
  .InovuaReactDataGrid__header-group__title,
  .InovuaReactDataGrid__footer-row-cell,
  .InovuaReactDataGrid__locked-row-cell,
  .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-padding) {
    position: relative;
    z-index: 1;
    padding: 0;

    .InovuaReactDataGrid__cell__content {
      padding: 8px 12px;
    }
  }

  .InovuaReactDataGrid__column-header--first-in-section.InovuaReactDataGrid__cell--no-padding
    .InovuaReactDataGrid__column-header__content {
    padding: 0;
  }

  .InovuaReactDataGrid__column-header--first-in-section,
  .InovuaReactDataGrid__cell--first.InovuaReactDataGrid__cell--no-leading-padding {
    .InovuaReactDataGrid__cell__content {
      padding-left: 12px;
    }
  }
}

.inovua-react-scroll-container__wrapper {
  width: calc(100% - 10px);
}

.inovua-react-scroll-container__wrapper div {
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
}

.inovua-react-scroll-container__wrapper div::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

// ----------------------------------------------------------------------
// ROWS

.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  .InovuaReactDataGrid__row {
    background-color: transparent;
  }
}

// ----------------------------------------------------------------------
// HOVER ROWS

.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  &.InovuaReactDataGrid--show-hover-rows {
    .InovuaReactDataGrid__row {
      &:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty) {
        .InovuaReactDataGrid__row-hover-target:hover {
          background-color: inherit;
          color: inherit;
        }

        &:not(.InovuaReactDataGrid__row--focused):not(:has(.InovuaReactDataGrid__cell--cell-selected)) {
          .InovuaReactDataGrid__row-hover-target:hover {
            &::before {
              content: '';
              position: absolute;
              top: -1px;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(145, 158, 171, 0.08);
              border: 1px solid $INOVUA_DATAGRID_BORDER_COLOR;
              //border-radius: 8px;
            }
          }
        }
      }
    }
  }
}

// ----------------------------------------------------------------------
// ACTIVE ROWS

.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  .InovuaReactDataGrid__row--active .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-background) {
    color: $INOVUA_DATAGRID_FONT_COLOR;
  }

  .InovuaReactDataGrid__row:has(.InovuaReactDataGrid__cell--cell-selected),
  .InovuaReactDataGrid__row--selected,
  .InovuaReactDataGrid__row--focused {
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //border-radius: 8px;
      border: none !important;
      background-color: rgba(51, 102, 255, 0.08);
    }

    .InovuaReactDataGrid__cell {
      color: $INOVUA_DATAGRID_FONT_COLOR;
    }
  }

  .InovuaReactDataGrid__row:has(.InovuaReactDataGrid__cell--cell-has-top-selected-sibling) {
    &::before {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .InovuaReactDataGrid__row:has(.InovuaReactDataGrid__cell--cell-has-bottom-selected-sibling) {
    &::before {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .InovuaReactDataGrid__row-active-borders-inner {
    border: 1px solid $INOVUA_DATAGRID_ROW_ACTIVE_BORDER_COLOR;
    //border-radius: 8px;
  }
}

// ----------------------------------------------------------------------
// CELLS

.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  .InovuaReactDataGrid__locked-row-cell--show-border-bottom,
  .InovuaReactDataGrid__footer-row-cell--show-border-bottom,
  .InovuaReactDataGrid__cell--show-border-bottom {
    border-bottom: none;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: $INOVUA_DATAGRID_BORDER_COLOR;
    }
  }
}

// ----------------------------------------------------------------------
// ACTIVE CELLS

.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  .InovuaReactDataGrid__cell--cell-active,
  .InovuaReactDataGrid__cell--cell-selected,
  .InovuaReactDataGrid__cell--cell-active.InovuaReactDataGrid__cell--cell-selected {
    .InovuaReactDataGrid__cell__selection-dragger {
      right: 0;
      background-color: #3366ff;
    }

    .InovuaReactDataGrid__cell__selection {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //border-radius: 8px;
      background: rgba(51, 102, 255, 0.08);

      &::after {
        border: 1px solid #3366ff;
        border-radius: inherit;
      }
    }
  }

  .InovuaReactDataGrid__cell--cell-active,
  .InovuaReactDataGrid__cell--cell-selected {
    &.InovuaReactDataGrid__cell--cell-has-top-selected-sibling {
      .InovuaReactDataGrid__cell__selection {
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        &::after {
          border-top-width: 0;
        }
      }
    }

    &.InovuaReactDataGrid__cell--cell-has-bottom-selected-sibling {
      .InovuaReactDataGrid__cell__selection {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        &::after {
          border-bottom-width: 0;
        }
      }
    }

    &.InovuaReactDataGrid__cell--cell-has-left-selected-sibling {
      .InovuaReactDataGrid__cell__selection {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &::after {
          border-left-width: 0;
        }
      }
    }

    &.InovuaReactDataGrid__cell--cell-has-right-selected-sibling {
      .InovuaReactDataGrid__cell__selection {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          border-right-width: 0;
        }
      }
    }
  }
}

// ----------------------------------------------------------------------
// FILTERS

.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  // Input wrapper
  .InovuaReactDataGrid__column-header__filter-wrapper {
    padding: 0;
    border-top: none;
  }

  // Filter base style
  .InovuaReactDataGrid__column-header__filter-wrapper {
    .InovuaReactDataGrid__column-header__filter,
    .InovuaReactDataGrid__column-header__filter.InovuaReactDataGrid__column-header__filter--string,
    .InovuaReactDataGrid__column-header__filter.InovuaReactDataGrid__column-header__filter--select {
      height: 100%;
      max-height: 100%;
      padding-left: 12px - (8px + 3px); // (8px + 3px) - space for input
      padding-right: 8px;
      color: #637381;
      background-color: transparent;

      &,
      &:hover {
        border: none;
        box-shadow: none;
      }

      &:hover {
        background-color: rgba(#919eab, 0.16);
      }

      &.inovua-react-toolkit-combo-box--focus {
        background-color: rgba(#919eab, 0.24);
      }

      svg,
      path {
        stroke: #637381;
      }
    }
  }

  // Input
  .InovuaReactDataGrid__column-header__filter.InovuaReactDataGrid__column-header__filter--string {
    //
  }

  // Numeric input
  .InovuaReactDataGrid__column-header__filter.InovuaReactDataGrid__column-header__filter--number {
    .inovua-react-toolkit-numeric-input__input {
      min-height: auto;
      padding: 0 8px;
      margin: 0;
    }
  }

  // Date picker
  .InovuaReactDataGrid__column-header__filter.InovuaReactDataGrid__column-header__filter--date {
    .inovua-react-toolkit-date-input__input {
      background-color: transparent;
    }
  }

  // Select
  .InovuaReactDataGrid__column-header__filter.InovuaReactDataGrid__column-header__filter--select {
    // input box
    .inovua-react-toolkit-combo-box__value {
      flex-grow: 1;
    }

    // caret box
    .inovua-react-toolkit-combo-box__tools {
      margin-left: auto;
      align-items: center;

      .inovua-react-toolkit-combo-box__loading-spinner ~ .inovua-react-toolkit-combo-box__toggle-icon {
        transform: rotate(-180deg);
      }

      .inovua-react-toolkit-combo-box__toggle-icon {
        width: 18px;
        height: 18px;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0003 16.0001C11.7666 16.0005 11.5402 15.9191 11.3603 15.7701L5.3603 10.7701C4.93504 10.4166 4.87684 9.78531 5.2303 9.36005C5.58376 8.9348 6.21504 8.87659 6.6403 9.23005L12.0003 13.7101L17.3603 9.39005C17.5669 9.22228 17.8318 9.14379 18.0965 9.17194C18.3611 9.20009 18.6036 9.33257 18.7703 9.54005C18.9554 9.74791 19.0456 10.0235 19.019 10.3006C18.9924 10.5777 18.8516 10.8312 18.6303 11.0001L12.6303 15.8301C12.4452 15.9556 12.2234 16.0154 12.0003 16.0001Z' fill='%23637381'/%3E%3C/svg%3E%0A");

        &::before {
          display: none;
        }

        svg {
          display: none;
        }
      }
    }

    // select list
    .inovua-react-toolkit-combo-box__list {
      //border-radius: 8px;
      min-width: 300px;
    }

    .inovua-react-toolkit-combo-box__value__tag {
      border-radius: 4px;
    }
  }

  // Checkbox
  .InovuaReactDataGrid__column-header__filter.InovuaReactDataGrid__column-header__filter--bool {
    padding: 0 0 0 1px;

    .inovua-react-toolkit-checkbox__icon-wrapper {
      border: 1.5px solid #637381;
      border-radius: 5px;

      svg {
        stroke: none;
      }
    }

    &.inovua-react-toolkit-checkbox--checked svg {
      fill: none;
    }
  }
}

// msc
.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  .InovuaReactDataGrid__cover + *[style]:not([class]) {
    display: none !important;
  }
}

// actionbar
.InovuaReactDataGrid--theme-#{$DATAGRID_THEME_NAME} {
  .DataGrid-actionbar {
    display: none;
    flex-shrink: 0;
  }
  .InovuaReactDataGrid__row:hover {
    .DataGrid-actionbar {
      display: block;
    }
  }
}
